import appVersion from "assets/images/app-version.svg";
import app1 from "assets/images/app-01.png";
import app2 from "assets/images/app-02.png";

const AppVersion = () => {
  return (
    <section className="section app-version" id={"get-app"}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="app-version-img text-center aos" data-aos="fade-up">
              <img className="img-fluid" src={appVersion} alt="App" />
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center" >
            <div className="app-version-blk aos" data-aos="fade-up">
              <h2>
                Get More In Our Application and Enjoy The Mobile App Version
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur. Nunc tristique neque
                tempor nisl feugiat lectus in. Placerat pharetra eleifend
                integer integer at. Nunc nunc eu arcu amet faucibus. Lorem ipsum
                dolor sit amet consectetur. Nunc{" "}
              </p>
              <h5>Download our Mobile app in Both Platform</h5>
              <div className="app-img-inside">
                <img className="img-fluid" src={app1} alt="App" />
                <img className="img-fluid" src={app2} alt="App" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AppVersion;
