import routeConstants from "./routeConstants";
import {
  About,
  LandingPage,
  Login,
  OnBoardScreen,
  ProfileSetting,
  Register,
} from "./routeImports";

const routesConfig = {
  private: [],
  public: [
    {
      path: routeConstants?.LANDING_PAGE,
      Component: LandingPage,
    },
    {
      path: routeConstants?.LOGIN,
      Component: Login,
    },
    {
      path: routeConstants?.REGISTER,
      Component: Register,
    },
    {
      path: routeConstants?.ONBOARD_SCREEN,
      Component: OnBoardScreen,
    },
    {
      path: routeConstants?.PROFILE_SETTING,
      Component: ProfileSetting,
    },
    {
      path: routeConstants?.ABOUT,
      Component: About,
    },
  ],
};

export default routesConfig;
