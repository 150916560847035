import dividerWhite from "assets/images/dividerWhite.svg";
import contactService from "assets/images/contact-service.png";
import { useState } from "react";
import OfferModal from "./components/OfferModal";
import offerContent from "./utils";
import { setLocal } from "services/localStorage";

const Offers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offerData, setOfferData] = useState([]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      setOfferData([]);
    }
  };

  const btnScroll = (id) => {
    if (id) {
      setLocal("id", id);
      const section = document.getElementById(id);
      section?.scrollIntoView({ behavior: "smooth" });
    }
    const focusedInput = document.getElementById("focus-input");
    if (focusedInput) {
      focusedInput?.focus();
    }
  };

  return (
    <section className="section offers" id="offers">
      <div className="container">
        <div className="offer-header mb-4">Our Offers</div>
        <img
          src={dividerWhite}
          alt="border"
          className="d-block text-center m-auto"
        />
        <div className="g-4 flex-wrap justify-content-center row mt-3">
          {offerContent?.map((offer, i) => {
            return (
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <div className="offer-cards" key={i}>
                  <div className="row flex-column align-items-center text-center px-2 py-3 m-0">
                    <div className="front_details">
                      <div className="offer-image">
                        <img
                          src={offer?.image}
                          className="img-fluid rounded-start"
                          alt="..."
                        />
                      </div>
                      <div className="text-center">
                        <div className="card-body pt-2 pb-0 mb-0 my-2">
                          <h5 className="card-title mb-0">{offer?.title}</h5>
                        </div>
                      </div>
                    </div>
                    <div
                      className="back_details"
                      onClick={() => {
                        toggleModal();
                        setOfferData(offer?.content || []);
                      }}
                    >
                      <div className="text-center">
                        <div className="card-body p-0">
                          <p className="card-text offer-text py-2 w-100  mb-0">
                            {offer?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row py-5">
          <div className="col-xl-12 col-md-12 col-12">
            <div className="bg-theme-section-white py-6 px-6 px-xl-0 rounded-4 ">
              <div className="row align-items-center">
                <div className="offset-xl-1 col-xl-6 col-md-7 col-12">
                  <div>
                    <h3 className="text-primary mb-3">
                      Find Your Next Great Job Opportunity!
                    </h3>
                    <p className="text-dark">
                      Join us in shaping the future. Your next big career move
                      starts now.
                    </p>
                    <button
                      className="btn-pehal w-50"
                      onClick={() => btnScroll("connect-us")}
                    >
                      Feel Free to Connect With Us
                    </button>
                  </div>
                </div>
                <div className="col-xl-5 col-md-5 col-12">
                  <div className="text-center">
                    <img
                      src={contactService}
                      alt="contact-for-service"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <OfferModal
          show={isModalOpen}
          data={offerData}
          toggleModal={toggleModal}
        />
      )}
    </section>
  );
};
export default Offers;
