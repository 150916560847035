import CountUp from "react-countup";
import Home from "assets/images/feature/home.svg";
import WorkForce from "assets/images/feature/workforce.svg";
import PinCode from "assets/images/feature/pincode.svg";
import TaskCompleted from "assets/images/feature/task-completed.svg";
import dividerWhite from "assets/images/dividerWhite.svg";

const featureContent = [
  {
    image: Home,
    count: "500",
    card_text: "Cities",
    isPlusIcon: true,
  },
  {
    image: WorkForce,
    count: "50000",
    card_text: "Workforce Count",
    isPlusIcon: true,
  },
  {
    image: PinCode,
    count: "5000",
    card_text: "Pin Code",
    isPlusIcon: true,
  },
  {
    image: TaskCompleted,
    count: "1 Million",
    card_text: "Task Completed",
  },
];

const Feature = () => {
  return (
    <section className="section feature">
      <div className="container">
        <div className="reach-header mb-2">Our Reach</div>
        <img
          src={dividerWhite}
          alt="border"
          className="d-block text-center m-auto"
        />
        <div className="row mt-3">
          {!!featureContent?.length &&
            featureContent?.map((feature, i) => {
              return (
                <div className="col-xl-3 col-md-6" key={i}>
                  <div
                    className="feature-item freelance-count aos"
                    data-aos="fade-up"
                  >
                    <div className="feature-icon">
                      <img
                        src={feature?.image}
                        className="img-fluid"
                        alt="icon"
                      />
                    </div>
                    <div className="feature-content course-count">
                      <h3 className="counter-up">
                        {feature?.isPlusIcon ? (
                          <>
                            <span>
                              <CountUp end={feature?.count} />
                            </span>
                            <span>+</span>
                          </>
                        ) : (
                          <span>{feature?.count}</span>
                        )}
                      </h3>
                      <p>{feature?.card_text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
export default Feature;
