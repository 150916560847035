import OwlCarousel from "react-owl-carousel";

const expertsContent = [
  {
    name: "Hire Vetted Software developer on contract",
    description: "",
  },
  {
    name: "No notice period immediately available",
    description: "",
  },
  {
    name: "Unique and Niche tech talents",
    description: "",
  },
  {
    name: "Resource management 24X7",
    description: "",
  },
  {
    name: "No Compliance",
    description: "",
  },
];

const Experts = () => {
  const options = {
    items: 3,
    margin: 30,
    dots: false,
    nav: true,
    smartSpeed: 2000,
    navText: [
      '<i class="fas fa-chevron-left"></i>',
      '<i class="fas fa-chevron-right"></i>',
    ],
    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1170: {
        items: 3,
      },
    },
  };

  return (
    <section className="section testimonial-section review" id="experts">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-header aos" data-aos="fade-up">
              <h2 className="header-title">
                Pehal Experts with 75 + IT Skills.
              </h2>
              <p>Your Path to Continuous Growth and Success!</p>
            </div>
          </div>
        </div>

        <OwlCarousel
          id="testimonial-slider"
          className="owl-carousel owl-theme testimonial-slider aos"
          data-aos="fade-up"
          loop
          {...options}
        >
          {!!expertsContent?.length &&
            expertsContent?.map((x, i) => {
              return (
                <div
                  className="review-blog d-flex justify-content-center align-items-center"
                  key={i}
                >
                  <div className="review-top">
                    <div className="review-info">
                      <h3>
                        <span>{x?.name}</span>
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
        </OwlCarousel>
      </div>
    </section>
  );
};
export default Experts;
