import crossRed from "assets/images/choose-us/cross-red.png";
import whiteRocket from "assets/images/choose-us/white_rocket_up.png";
import greenRocket from "assets/images/choose-us/rocket_up.png";
import dividerGreen from "assets/images/dividerGreen.svg";

const ChooseUs = () => {
  return (
    <section className="w-100 my-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-header text-center aos" data-aos="fade-up">
              <h2 className="mb-4">
                Why Choose <span>Pehal Gig</span> ?
              </h2>
              <h4 className="text-dark">
                Join our growing GiG community of satisfied users and discover
                the endless possibilities with Pehal. Download now and
                experience the future of seamless task access and productivity
                with real time earning.
              </h4>
            </div>
            <img
              src={dividerGreen}
              alt="border"
              className="d-block text-center m-auto"
            />
            {/* <div className="offer-border border-green w-50 my-4"></div> */}
          </div>
          <div className="why_choose_main_div">
            <div className="why_choose_table mt-4">
              <table className="choose_table_main">
                <thead>
                  <tr>
                    <th>Features</th>
                    <th>Pehal Gig</th>
                    <th>In House Execution</th>
                    <th>Other Outsourcing Agency</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Outcome Based Payout</td>
                    <td>
                      <img src={whiteRocket} alt="" className="rocket-image" />
                    </td>
                    <td>
                      <img src={crossRed} alt="" />
                    </td>
                    <td>
                      <img src={crossRed} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>End to End Execution</td>
                    <td>
                      <img src={whiteRocket} alt="" className="rocket-image" />
                    </td>
                    <td>
                      <img src={greenRocket} alt="" className="rocket-image" />
                    </td>
                    <td>
                      <img src={crossRed} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>Zero Hiring Costs</td>
                    <td>
                      <img src={whiteRocket} alt="" className="rocket-image" />
                    </td>
                    <td>
                      <img src={crossRed} alt="" />
                    </td>
                    <td>
                      <img src={crossRed} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>Quality &amp; Risk Management</td>
                    <td>
                      <img src={whiteRocket} alt="" className="rocket-image" />
                    </td>
                    <td>
                      <img src={greenRocket} alt="" className="rocket-image" />
                    </td>
                    <td>
                      <img src={crossRed} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>Rapid &amp; Lean Execution</td>
                    <td>
                      <img src={whiteRocket} alt="" className="rocket-image" />
                    </td>
                    <td>
                      <img src={crossRed} alt="" />
                    </td>
                    <td>
                      <img src={greenRocket} alt="" className="rocket-image" />
                    </td>
                  </tr>
                  <tr>
                    <td>PAN India Execution</td>
                    <td>
                      <img src={whiteRocket} alt="" className="rocket-image" />
                    </td>
                    <td>
                      <img src={crossRed} alt="" />
                    </td>
                    <td>
                      <img src={greenRocket} alt="" className="rocket-image" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ChooseUs;
