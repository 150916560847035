import dividerWhite from "assets/images/dividerWhite.svg";
import commitImg from "assets/images/commit_image.svg";

const Commit = () => {
  return (
    <section className="we_commit py-5">
      <div className="container">
        <div className="row align-items-end justify-content-start">
          <div className="col-12 col-lg-6 ">
            <div className="commit_details">
              <h2 className="mb-2">We Commit, We Fulfill.</h2>
              <img src={dividerWhite} alt="border" className="my-3" />
              <p>
                We've garnered satisfaction from over 100 prestigious brands
                spanning across sectors such as E-commerce, Consumer Packaged
                Goods (CPG), Hospitality, Mobility, Banking, Financial Services,
                and more.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 we_commit_img">
            <div className="h-100 w-100 row">
              <div className="col-12">
                <img src={commitImg} alt="/" className="mb-4 w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Commit;
