import React from "react";
import approach from "assets/images/approach.svg";
import dividerGreen from "assets/images/dividerGreen.svg";

const Approach = () => {
  return (
    <section className="section approach-section" id="approach">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3">
            <div className="section-header text-center aos" data-aos="fade-up">
              <h2>Our operational approach.</h2>
            </div>
            <img
              src={dividerGreen}
              alt="border"
              className="d-block text-center m-auto"
            />
            {/* <div className="offer-border border-green w-50 my-4"></div> */}
          </div>
        </div>
        <div className="approach">
          <div className="row w-100">
            <div className="col-12 col-lg-6 ">
              <div className="approach-data h-100 w-100">
                <img src={approach} alt="approach" className="h-100" />
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <div className="approach-points w-100">
                <div className="approach-point">
                  <div className="point-number">1</div>
                  <div className="point-content">
                    We comprehend your challenges and needs, and we suggest
                    outcome-based pricing models.
                  </div>
                </div>
                <div className="approach-point">
                  <div className="point-number">2</div>
                  <div className="point-content">
                    Your requirements are translated into tasks and seamlessly
                    integrated into our mobile/web application.
                  </div>
                </div>
                <div className="approach-point">
                  <div className="point-number">3</div>
                  <div className="point-content">
                    We carefully select and prepare trusted gig workers from
                    Pehal to carry out the tasks after rigorous training and
                    evaluation
                  </div>
                </div>
                <div className="approach-point">
                  <div className="point-number">4</div>
                  <div className="point-content">
                    Pehal continuously supervises the real-time execution,
                    guaranteeing both delivery and the highest quality of
                    outcomes.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Approach;
