import { Link } from "react-router-dom";
import contactService from "assets/images/contact-service.png";
import { setLocal } from "services/localStorage";

const Opportunity = () => {
  const btnScroll = (id) => {
    if (id) {
      setLocal("id", id);
      const section = document.getElementById(id);
      section?.scrollIntoView({ behavior: "smooth" });
    }
    const focusedInput = document.getElementById("focus-input");
    if (focusedInput) {
      focusedInput?.focus();
    }
  };
  return (
    // <section className="section job-register" id="register">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-12">
    //         <div className="register-job-blk">
    //           <div className="job-content-blk aos" data-aos="fade-up">
    //             <h1>Find Your Next Great Job Opportunity!</h1>
    //             <p>
    //               Quisque pretium dolor turpis, quis blandit turpis semper ut.
    //               Nam malesuada eros nec luctus laoreet.
    //             </p>
    //           </div>
    //           <div className="see-all mt-0 aos" data-aos="fade-up">
    //             <Link to="/register" className="btn all-btn">
    //               Register Now
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <section className="service-cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-md-12 col-12">
            <div className="bg-theme-section py-6 px-6 px-xl-0 rounded-4 ">
              <div className="row align-items-center">
                <div className="offset-xl-1 col-xl-6 col-md-7 col-12">
                  <div>
                    <h3 className="text-white mb-3">
                      {/* Let's connect to Pehal to explore our product range! */}
                      Find Your Next Great Job Opportunity!
                    </h3>
                    <p className="text-white">
                      {/* Welcome to Pehal Gig Private Limited - Where Quality Meets
                      Diversity and Excellence! */}
                      Join us in shaping the future. Your next big career move
                      starts now.
                    </p>
                    <button
                      className="btn-pehal w-50"
                      onClick={() => btnScroll("connect-us")}
                    >
                      Feel Free to Connect With Us
                    </button>
                  </div>
                </div>
                <div className="col-xl-5 col-md-5 col-12">
                  <div className="text-center">
                    <img
                      src={contactService}
                      alt="contact-for-service"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Opportunity;
