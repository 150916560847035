// import { Suspense } from "react";
import { Navigate, Routes as ReactRouterRoutes, Route } from "react-router-dom";

// import Loader from "components/common/Loader";
// import routesConfig from "./routes.config";
import Layout from "modules/Layout";
import { Suspense } from "react";
import Loader from "components/common/Loader";
import routesConfig from "./routes.config";
import routeConstants from "./routeConstants";
import Home from "modules/Home";

const Public = (route) => {
  // Logic for public routes
  const { Component } = route;

  return (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );
};

// const Private = (route) => {
//   // Logic for Private routes
//   const { Component } = route;
//   return (
//     <Suspense fallback={<Loader />}>
//       <Component />
//     </Suspense>
//   );
// };

const createNestedRoutes = (routes, RouteType) => {
  return routes.map((route, i) => {
    if (!route.Component) {
      throw new Error("Component must be required....");
    }
    if (route.children) {
      return (
        <Route path={route.path} key={i} element={<RouteType {...route} />}>
          {createNestedRoutes(route.children, RouteType)}
        </Route>
      );
    } else {
      return (
        <Route
          key={i}
          index={route.index}
          path={route.path}
          element={<RouteType {...route} />}
        />
      );
    }
  });
};

const Routes = () => {
  const isAuth = false;
  const { public: publicRoutes } = routesConfig;
  return (
    <ReactRouterRoutes>
      {isAuth ? (
        <>Private Routes</>
      ) : (
        <>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            {createNestedRoutes(publicRoutes, Public)}
          </Route>
          <Route
            path="*"
            element={<Navigate to={routeConstants?.LANDING_PAGE} />}
          />
        </>
      )}
    </ReactRouterRoutes>
  );
};
export default Routes;
