const HeaderContent = [
  {
    parentMenu: "Home",
    scrollId: "home",
  },
  {
    parentMenu: "Clients",
    scrollId: "clients",
  },
  {
    parentMenu: "About Us",
    scrollId: "about",
  },
  {
    parentMenu: "Use Cases",
    scrollId: "offers",
  },
  {
    parentMenu: "Join Us",
    scrollId: "join-us",
  },
  {
    parentMenu: "Get App",
    scrollId: "get-app",
  },
  {
    parentMenu: "Connect Us",
    scrollId: "connect-us",
  },
];

export default HeaderContent;
