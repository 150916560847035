import loader from "assets/images/load-icon.png";

const Loader = () => {
  return (
    <div id="global-loader">
      <div className="whirly-loader"> </div>
      <div className="loader-img">
        <img src={loader} alt="loader_image" className="img-fluid" />
      </div>
    </div>
  );
};
export default Loader;
