import AppVersion from "modules/AppVersion";
import Approach from "modules/Approach";
import Clients from "modules/Clients";
import Developer from "modules/Developer";
import Feature from "modules/Feature";
import Footer from "modules/Footer";
import Offers from "modules/Offers";
import Opportunity from "modules/Opportunity";
import ChooseUs from "modules/choose-us";
import { useEffect } from "react";
import HomeContent from "./components/HomeContent";
import Experts from "modules/Experts";
import ContactUs from "modules/ContactUs";
import About from "modules/About";
import Commit from "modules/Commit";
import { useLocation } from "react-router-dom";
import { getLocal, setLocal } from "services/localStorage";
import JoinUs from "modules/JoinUs";

const Home = () => {
  const location = useLocation();
  useEffect(() => {
    document.body.className = "home-page bg-one";
  }, []);

  useEffect(() => {
    const id = getLocal("id");
    console.log("Here", id, !id);
    if (!id) {
      setLocal("id", "home");
    }
  }, [location]);

  return (
    <>
      <HomeContent />
      <Clients />
      <About />
      <Offers />
      <Experts />
      <Feature />
      <Approach />
      <Commit />
      <ChooseUs />
      <JoinUs />
      <AppVersion />
      <Opportunity />
      <ContactUs />
      <Footer />
    </>
  );
};
export default Home;
