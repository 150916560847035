import approach from "assets/images/approach.svg";
import dividerWhite from "assets/images/dividerWhite.svg";
import dividerGreen from "assets/images/dividerGreen.svg";
import location from "assets/images/location.png";
import call from "assets/images/call.png";

const ContactUs = () => {
  return (
    <section className="section contact-section px-5" id="connect-us">
      {/* <div className="container"> */}
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-8 locations_info">
          <div class="maps">
            <h2>GLOBAL PRESENCE</h2>
            <h6>OUR OFFICES ARE LOCATED AT:</h6>
            <img src={dividerWhite} alt="" />
            <ul>
              <li>
                <h5 className="m-0">Registered Address </h5>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={location} className="me-2" alt="" /> A-501,
                  SIGNATURE-2 SARKHEJ SANAND HIGHWAY Sarkhej Sanand Ahmedabad ,
                  Gujarat
                </div>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={call} className="me-2" alt="" /> +91 835 698 8262
                </div>
              </li>
              <li>
                <h5 className="m-0">Mumbai Office </h5>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={location} className="me-2" alt="" /> Lilac 5, Godrej
                  Sky Garden, Panvel 410206
                </div>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={call} className="me-2" alt="" /> +91 797 772 8832
                </div>
              </li>
              <li>
                <h5 className="m-0">Gandhinagar Office </h5>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={location} className="me-2" alt="" /> Unit no. 2b/3 ,
                  ground floor at IT4, Infocity IT metropolis, Gandhinagar -
                  382009
                </div>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={call} className="me-2" alt="" /> +91 942 627 2844
                </div>
              </li>
              <li>
                <h5 className="m-0">UK Office </h5>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={location} className="me-2" alt="" /> 42, Trinity
                  Street, Dorchester, Dorset, England, UK DT1 1TT
                </div>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={call} className="me-2" alt="" /> +44 7428 247328
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4 contact_section_form">
          <div className="contact-form ps-4 py-4 rounded">
            <h2>SAY HELLO!</h2>
            <img src={dividerGreen} alt="" />
            <div className="col-12 d-flex flex-column mb-3">
              <label className="w-100 mb-0">Name</label>
              <input
                className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white"
                placeholder="Enter Name"
                type="text"
                id="focus-input"
              />
            </div>
            <div className="col-12 d-flex flex-column mb-3">
              <label className="w-100 mb-0">Email</label>
              <input
                className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white"
                placeholder="Enter Email"
                type="email"
              />
            </div>
            <div className="col-12 d-flex flex-column mb-3">
              <label className="w-100 mb-0">Phone No.</label>
              <input
                className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white"
                placeholder="Enter Phone no."
                type="text"
              />
            </div>
            <div className="col-12 d-flex flex-column mb-3">
              <label className="w-100 mb-0">Contact Email Inquiry</label>
              <select className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white">
                <option value="" disabled>
                  please select
                </option>
                <option value="0">Gig Partners</option>
                <option value="1">Gig Clients</option>
              </select>
            </div>
            <div className="col-12 d-flex flex-column mb-3">
              <label className="w-100 mb-0">Message</label>
              <textarea
                className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white"
                placeholder="Enter Message"
                rows={4}
              />
            </div>
            <div className="col-12 text-center">
              <button type="submit" className="contact-submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};
export default ContactUs;
