import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import HeaderContent from "./HeaderContent";

import logo from "assets/images/Logo.png";
import regIcon from "assets/images/reg-icon.svg";
import lockIcon from "assets/images/lock-icon.svg";
import { getLocal } from "services/localStorage";

const Header = ({
  toggleMenu = () => {},
  closeMenu = () => {},
  sidebarDropDown = () => {},
  scrollToSection = () => {},
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 100;

      if (window.scrollY > scrollThreshold) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const btnScroll = (id) => {
    if (id) {
      scrollToSection(id);
    }
    const focusedInput = document.getElementById("focus-input");
    if (focusedInput) {
      focusedInput?.focus();
    }
  };

  const activeTab = getLocal("id");

  return (
    <>
      <header
        className={`header ${isSticky ? "sticky" : ""} ${
          ["/profile-setting", "/about-us"]?.includes(location?.pathname)
            ? "header-bg"
            : ""
        }`}
      >
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <button
              id="mobile_btn"
              className="bg-transparent border-0"
              onClick={toggleMenu}
            >
              <span className="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
            <Link to="/" className="navbar-brand logo">
              <img src={logo} alt="logo" className="img-fluid" />
            </Link>
          </div>
          <div className="main-menu-wrapper">
            <div className="menu-header">
              <Link to="/" className="menu-logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
              <button
                id="menu_close"
                className="menu-close bg-transparent border-0"
                onClick={closeMenu}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <ul className="main-nav">
              {HeaderContent?.map((x, i) => {
                return (
                  <li
                    key={i}
                    className={`has-submenu ad ${
                      x?.scrollId === activeTab ? "active" : ""
                    }`}
                  >
                    <NavLink
                      onClick={() => scrollToSection(x?.scrollId)}
                      className="border-0 bg-transparent"
                    >
                      {x?.parentMenu} {x?.parentIcon}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <ul className="nav header-navbar-rht reg-head">
            <li>
              <Link
                className="login-btn"
                onClick={() => btnScroll("connect-us")}
              >
                Register as Client
              </Link>
            </li>
            <li>
              <Link
                className="login-btn"
                onClick={() => btnScroll("connect-us")}
              >
                Register as Gig Partners
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
export default Header;
