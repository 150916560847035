import backToTop from "assets/images/top-icon.svg";
import { useEffect, useRef } from "react";

const BackToTop = () => {
  const scrollButtonRef = useRef(null);

  const handleScroll = () => {
    const scrollTarget = document.querySelector(".scroll-to-target");
    if (scrollTarget) {
      if (window.scrollY > 0) {
        scrollTarget.classList.add("open");
      } else {
        scrollTarget.classList.remove("open");
      }
    }
  };

  const scrollToTop = () => {
    const targetElement = document.querySelector("html"); // Change this target if needed
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove scroll event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClick = () => {
    scrollToTop();
    // Remove the scroll event listener temporarily to prevent multiple scrolls
    window.removeEventListener("scroll", handleScroll);
    // Reattach the scroll event listener after a short delay (e.g., 500ms)
    setTimeout(() => {
      window.addEventListener("scroll", handleScroll);
    }, 500);
  };

  return (
    <button
      className="scroll-top scroll-to-target open"
      data-target="html"
      onClick={handleButtonClick}
      ref={scrollButtonRef}
    >
      <span className="ti-angle-up">
        <img src={backToTop} className="img-fluid" alt="back-to-top" />
      </span>
    </button>
  );
};

export default BackToTop;
