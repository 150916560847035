const routeConstants = {
  LANDING_PAGE: "",
  LOGIN: "login",
  REGISTER: "register",
  ONBOARD_SCREEN: "onboard-screen",
  PROFILE_SETTING: "profile-setting",
  ABOUT: "about-us",
};

export default routeConstants;
