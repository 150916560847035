import Header from "./components/Header";
import BackToTop from "components/common/BackToTop";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { getLocal, removeLocal, setLocal } from "services/localStorage";

const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const isHomePage = !["/login", "/register"]?.includes(location?.pathname);

    if (!isHomePage) {
      document.body.className = "account-page";
    }
  }, [location?.pathname]);

  const toggleMenu = () => {
    var sidebarOverlay = document.createElement("div");
    sidebarOverlay.className = "sidebar-overlay";
    document.body.appendChild(sidebarOverlay);

    // Function to toggle the sidebar
    document
      .getElementById("mobile_btn")
      .addEventListener("click", function () {
        var mainWrapper = document.querySelector(".main-wrapper");
        var sidebarOverlay = document.querySelector(".sidebar-overlay");
        var htmlElement = document.querySelector("html");

        mainWrapper.classList.toggle("slide-nav");
        sidebarOverlay.classList.toggle("opened");
        htmlElement.classList.add("menu-opened");
        return false;
      });
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration (in milliseconds)
      once: true, // Whether animation should only happen once while scrolling down
      mirror: false, // Whether elements should animate out while scrolling up
      // You can customize more options here
    });
  }, []);

  const closeMenu = () => {
    document
      .getElementById("menu_close")
      .addEventListener("click", function () {
        var htmlElement = document.querySelector("html");
        var sidebarOverlay = document.querySelector(".sidebar-overlay");
        var mainWrapper = document.querySelector(".main-wrapper");

        htmlElement.classList.remove("menu-opened");
        sidebarOverlay.classList.remove("opened");
        mainWrapper.classList.remove("slide-nav");
      });
    setIsMenuOpen(false);
  };

  const sidebarDropDown = () => {
    // Function to close the sidebar when clicking the overlay
    document
      .querySelector(".sidebar-overlay")
      ?.addEventListener("click", function () {
        var htmlElement = document.querySelector("html");
        var sidebarOverlay = document.querySelector(".sidebar-overlay");
        var mainWrapper = document.querySelector(".main-wrapper");

        htmlElement.classList.remove("menu-opened");
        sidebarOverlay.classList.remove("opened");
        mainWrapper.classList.remove("slide-nav");
      });
  };

  const scrollToSection = (id) => {
    setLocal("id", id);
    const section = document.getElementById(id);
    section?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const id = getLocal("id");
    removeLocal("id");
    if (id) {
      const section = document.getElementById(id);
      section?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      {isMenuOpen && (
        <div className="sidebar-overlay" onClick={closeMenu}></div>
      )}
      <div className={`main-wrapper ${isMenuOpen ? "slide-nav" : ""}`}>
        {![
          "/login",
          "/register",
          "/onboard-screen",
          "/profile-setting",
          "/about-us",
        ]?.includes(location?.pathname) && <BackToTop />}

        {!["/onboard-screen", "/login", "/register"]?.includes(
          location.pathname
        ) && (
          <Header
            toggleMenu={toggleMenu}
            closeMenu={closeMenu}
            // sidebarDropDown={sidebarDropDown}
            scrollToSection={scrollToSection}
            isMenuOpen={isMenuOpen}
          />
        )}
        <Outlet />
      </div>
    </>
  );
};
export default Layout;
