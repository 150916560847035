import company1 from "assets/images/company/company-logo-01.svg";
import company2 from "assets/images/company/company-logo-02.svg";
import company3 from "assets/images/company/company-logo-03.svg";
import company4 from "assets/images/company/company-logo-04.svg";
import company5 from "assets/images/company/company-logo-05.svg";
import company6 from "assets/images/company/company-logo-06.svg";
import company7 from "assets/images/company/company-logo-07.svg";
import OwlCarousel from "react-owl-carousel";

const options = {
  items: 8,
  margin: 30,
  dots: false,
  nav: false,
  smartSpeed: 2000,
  navText: [
    '<i class="fas fa-chevron-left"></i>',
    '<i class="fas fa-chevron-right"></i>',
  ],
  loop: true,
  responsiveClass: true,
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1170: {
      items: 6,
    },
  },
};

const Clients = () => {
  return (
    <section className="section top-company" id="clients">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-header text-center aos" data-aos="fade-up">
              <h2 className="client-title">
                Trusted By <span>100+</span> Clients{" "}
              </h2>
            </div>
          </div>
        </div>
        <OwlCarousel
          id="company-slider"
          className="owl-carousel owl-theme testimonial-slider aos"
          data-aos="fade-up"
          {...options}
        >
          <div className="company-logos">
            <img src={company1} alt="logo-1" />
          </div>
          <div className="company-logos">
            <img src={company2} alt="logo-2" />
          </div>
          <div className="company-logos">
            <img src={company3} alt="logo-3" />
          </div>
          <div className="company-logos">
            <img src={company4} alt="logo-4" />
          </div>
          <div className="company-logos">
            <img src={company5} alt="logo-5" />
          </div>
          <div className="company-logos">
            <img src={company6} alt="logo-6" />
          </div>
          <div className="company-logos">
            <img src={company7} alt="logo-7" />
          </div>
          <div className="company-logos">
            <img src={company1} alt="logo-1" />
          </div>
        </OwlCarousel>
      </div>
    </section>
  );
};
export default Clients;
