import marketing from "assets/images/about/Marketing.svg";
import enterprise from "assets/images/about/enterprise.svg";
import workFlexible from "assets/images/about/Working-flexible.svg";
import rightArrow from "assets/images/arrow.png";
import dividerGreen from "assets/images/dividerGreen.svg";
import { useEffect } from "react";

const About = () => {
  useEffect(() => {
    document.body.className = "";
  }, []);

  return (
    <>
      <section className="section approach-section about-section" id="about">
        <div className="container">
          <div className="row">
            <div
              className="section-header text-center text-dark aos mb-5"
              data-aos="fade-up"
            >
              <p className="mb-0 company_name text-primary">
                Pehal GigTech Private Limited
              </p>
              <h2 className="my-2">
                About <span>Pehal</span>
              </h2>
              <div className="quotes">
                <blockquote>Kal Ki Pehal Ajj Se</blockquote>
              </div>
              <img src={dividerGreen} alt="border" className="my-3" />
              <p className="mb-0">
                Pehal is a groundbreaking startup dedicated to revolutionizing
                the gig economy landscape in India. With a vast and mobile
                community of over million gig partners spanning across 5000+ pin
                codes, we are India's foremost work-as-a-service (#WaaS)
                platform. Our primary objective is to empower enterprises to
                streamline their core business functions through end-to-end
                management and results-driven execution.
              </p>
            </div>
          </div>
          <div className="approach row mb-5">
            <div className="col-12 about_us_details mb-5">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6 ">
                  <div className="approach-data h-100 w-100 row">
                    <img
                      src={enterprise}
                      alt="/"
                      className="w-100 about-marketing"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6 ">
                  <div className="">
                    <h3>Enterprise</h3>
                    <div className="point-content">
                      For us enterprise represents established businesses and
                      organizations that operate on traditional models. They
                      typically have well-defined structures, hierarchies, and
                      processes. To reframe the enterprise in this context, it's
                      essential to:
                    </div>
                  </div>
                  <ul>
                    <li>
                      <h5>
                        <img src={rightArrow} alt="" className="me-2" /> Embrace
                        Innovation
                      </h5>
                      <p>
                        We provide innovating technologies and business models
                        to stay competitive. This may involve investing in
                        digital transformation, automation, and data analytics
                        to improve efficiency and customer engagement.
                      </p>
                    </li>
                    <li>
                      <h5>
                        <img src={rightArrow} alt="" className="me-2" />{" "}
                        Collaboration
                      </h5>
                      <p>
                        Enterprises can collaborate with our gig workforce to
                        access specialized skills and resources on a project
                        basis. This flexible approach allows them to adapt
                        quickly to changing market demands.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 about_us_details mb-5">
              <div className="row align-items-center about_us_details_mobile">
                <div className="col-12 col-lg-6 ">
                  <div className="">
                    <h3>Gig Workforce</h3>
                    <div className="point-content">
                      The gig workforce consists of freelancers, independent
                      contractors, and temporary workers who provide their
                      services on a project or task basis. To reframe the gig
                      workforce:
                    </div>
                  </div>
                  <ul>
                    <li>
                      <h5>
                        <img src={rightArrow} alt="" className="me-2" /> Skills
                        and Expertise
                      </h5>
                      <p>
                        Recognize the gig workforce as a valuable source of
                        specialized skills and expertise. Business can tap into
                        this talent pool to augment their in-house teams when
                        necessary.
                      </p>
                    </li>
                    <li>
                      <h5>
                        <img src={rightArrow} alt="" className="me-2" />{" "}
                        Workforce Flexibility
                      </h5>
                      <p>
                        Embrace the flexibility of the gig workforce to scale up
                        or down according to project demands. This agility can
                        help reduce labor costs and enhance resource allocation.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-6 ">
                  <div className="approach-data h-100 w-100 row">
                    {/* <div className="col-6">
                      <img src={skill} alt="/" className="w-100" />
                    </div>
                    <div className="col-6">
                      <img src={work} alt="/" className="w-100" />
                    </div> */}
                    <img
                      src={workFlexible}
                      alt="/"
                      className="w-100 about-marketing"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 about_us_details">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6 ">
                  <div className="approach-data h-100 w-100 row">
                    {/* <div className="col-6">
                      <img src={customer} alt="/" className="mb-4 w-100" />
                      <img src={marketing} alt="/" className="w-100" />
                    </div>
                    <div className="col-6">
                      <img src={marketingData} alt="/" className="mb-4 w-100" />
                    </div> */}
                    <img
                      src={marketing}
                      alt="/"
                      className="w-100 about-marketing"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6 ">
                  <div className="">
                    <h3>Marketing</h3>
                    <div className="point-content">
                      Our Marketing solutions team understand how to influence
                      the brand choice at the point of buying through effective
                      brand education and exceptional experiences that drive
                      sales and repeat orders with..
                    </div>
                  </div>
                  <ul>
                    <li>
                      <h5>
                        <img src={rightArrow} alt="" className="me-2" />{" "}
                        Customer-Centric Approach
                      </h5>
                      <p>
                        With access to big data and advanced analytics,
                        enterprises can adopt a more customer-centric approach
                        to marketing. Personalization and targeted campaigns are
                        essential to engage customers effectively.
                      </p>
                    </li>
                    <li>
                      <h5>
                        <img src={rightArrow} alt="" className="me-2" />{" "}
                        Omni-Channel Marketing
                      </h5>
                      <p>
                        Utilize various marketing channels, both digital and
                        traditional, to reach customers where they are. This may
                        include social media, email marketing, content
                        marketing, and influencer partnerships.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row"></div>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
