import { Link } from "react-router-dom";
import pehalLogo from "assets/images/Logo.png";
import email from "assets/images/email.svg";

const footerContent = [
  {
    title: "Address ",
    isContent: true,
    address:
      "42, Trinity Street, Dorchester, Dorset, England, UK DT1 1TT +44 7428 247328",
    isOtherLink: false,
  },
  {
    title: "Company",
    links: [
      { path: "/about", name: "Pehal Gig" },
      { path: "/blog", name: "Career" },
      // { path: "login", name: "Job Packages" },
      // { path: "/register", name: "Jobs Featured" },
      // { path: "/forgot-password", name: "Post A Job" },
    ],
    isOtherLink: false,
  },
  {
    title: "Resource",
    links: [
      { path: "/about", name: "Blogs" },
      // { path: "/blog", name: "Freelancer Details" },
      // { path: "login", name: "Project" },
      // { path: "/register", name: "Project Details" },
      // { path: "/forgot-password", name: "Post Project" },
    ],
    isOtherLink: true,
  },
];

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="footer-top aos" data-aos="fade-up">
        <div className="container">
          <div className="row">
            {!!footerContent?.length &&
              footerContent?.map((footer, i) => {
                return (
                  <div
                    className={`${
                      !footer?.isOtherLink ? "col-xl-3" : "col-xl-2"
                    } col-md-6`}
                    key={i}
                  >
                    <div className="footer-widget footer-menu">
                      <h2 className="footer-title">{footer?.title}</h2>
                      {footer?.isContent && <p>{footer?.address}</p>}
                      <ul>
                        {!!footer?.links?.length &&
                          footer?.links?.map((link, i) => {
                            return (
                              <li key={i}>
                                <Link to={link?.path}>
                                  <i className="fas fa-angle-right me-1" />{" "}
                                  {link?.name}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            <div className="col-xl-4 col-md-6">
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">Email Address</h2>
                <div className="d-flex align-items-center mb-3">
                  <img src={email} alt="email" />

                  <p className="mb-0 ms-2">sales@pehal.com</p>
                </div>

                <div className="social-icon d-flex">
                  <span>Follow us on : </span>
                  <ul>
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/pehal-gigtech"
                        className="icon"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" className="icon" target="_blank">
                        <i
                          className="fab fa-facebook-f"
                          title="coming soon"
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" className="icon" target="_blank">
                        <i className="fab fa-instagram" title="coming soon"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" className="icon" target="_blank">
                        <i className="fab fa-twitter" title="coming soon"></i>{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="footer-bottom">
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 col-lg-6 ">
                <div className="footer-bottom-logo">
                  <Link to="/" className="menu-logo">
                    <img src={pehalLogo} className="img-fluid" alt="Logo" />
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 right-text">
                <ul className="nav footer-bottom-nav">
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/pehal-gigtech"
                      className="icon"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Terms of use</Link>
                  </li>
                </ul>
                <div className="copyright-text">
                  <p className="mb-0">
                    Copyright 2023 © Pehal Gigtech Private Limited. All right
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
