import lazy from "utils/lazy";

export const LandingPage = lazy(() => import("../modules/Layout"));

export const Login = lazy(() => import("../modules/Login"));

export const Register = lazy(() => import("../modules/Register"));

export const OnBoardScreen = lazy(() => import("../modules/OnBoardScreen"));

export const ProfileSetting = lazy(() => import("../modules/ProfileSetting"));

export const About = lazy(() => import("../modules/About"));
